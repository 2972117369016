import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import { LIBList, LIBListItem, LIBHeadline, LIBLink } from "../../components/LinkInBio";

const LinkInBioPage = () => {
	return (
	  <Layout>
	    <Seo title="Link in Bio" />
		<p>&nbsp;</p>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/photos/pressRes/_MG_1300.jpg"
			alt="Jesse Rivest press image"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-large"
			width={500}
			/>
		</div>
		<div className="main--centerwrapper main--centerwrapper__column">
			<h4 className="main--centered">@jesserivest</h4>
			<p className="main--centered centeredText snugTop">
				Singer-songwriter and guitarist split-based between the<br />
				capital of Brazil and hometown Kelowna, British Columbia.<br />
				Baking, bicycles, nature, Sunday adventures.
			</p>
		</div>
		<LIBList>

			<LIBListItem>
				<LIBHeadline>
					Upcoming Livestreams
				</LIBHeadline>
				<LIBLink>
					<a href='//www.ursalive.com/jesserivest'>
						Ursa Live
					</a>
					&nbsp;or&nbsp;
					<a href="//www.youtube.com/@jesserivest/streams">
						Youtube
					</a>
				</LIBLink>
			</LIBListItem>

			<LIBListItem>
				<LIBHeadline>
					Dec: 🎶 Distant December ☃️
				</LIBHeadline>
				<LIBLink>
					<a href='//open.spotify.com/album/6UgB9Z7wD6Og6y7IxwKJST?si=xprmMiGmRbW99DGK2al2rg'>
						Listen Spotify
					</a>
					&nbsp;or&nbsp;
					<a href="//jesserivest.bandcamp.com/album/distant-december">
						Listen Bandcamp
					</a>
				</LIBLink>
			</LIBListItem>

			<LIBListItem>
				<LIBHeadline>
					2023 short-list playlist 🎶
				</LIBHeadline>
				<LIBLink>
					<a href="//open.spotify.com/playlist/4zRZewDbt2EbF3qRrhje8z?si=7d96b739af1d4eda">
						Listen Spotify
					</a>
				</LIBLink>
			</LIBListItem>

			<LIBListItem>
				<LIBHeadline>
					CDs and tee shirts available
				</LIBHeadline>
				<LIBLink>
					<Link to="/merch/">
						See merch
					</Link>
				</LIBLink>
			</LIBListItem>

			<LIBListItem>
				<LIBHeadline>
					Jun: 🎶 No Lights Shine Out to Me 🌃
				</LIBHeadline>
				<LIBLink>
					<a href='//open.spotify.com/album/0B8ptHEf9lyfwvhyZilQ03?si='>
						Listen Spotify
					</a>
					&nbsp;or&nbsp;
					<a href="//jesserivest.bandcamp.com/album/no-lights-shine-out-to-me">
						Listen Bandcamp
					</a>
				</LIBLink>
			</LIBListItem>

			<LIBListItem>
				<LIBHeadline>
					Get to know me
				</LIBHeadline>
				<LIBLink>
					<Link to="/music/">
						Playlist: select tunes
					</Link>
					&nbsp;or&nbsp;
					<a href='//www.youtube.com/playlist?list=PLUnT5wM9jMYNnG6Hs5geY3Qi7O2hDQwt4'>
						Playlist at Youtube
					</a>
				</LIBLink>
			</LIBListItem>

			<LIBListItem>
				<LIBHeadline>
					Get on board
				</LIBHeadline>
				<LIBLink>
					<a href="//bit.ly/email-list-JR">
						Mailing list: join me
					</a>
				</LIBLink>
			</LIBListItem>

		</LIBList>
	  </Layout>
  );
}
export default LinkInBioPage
